


import React, { useContext, useEffect, useState } from 'react';
import { FaBarcode, FaBoxOpen, FaWarehouse, FaArrowDown, FaCogs, FaSearch, FaHome, FaUser, FaSignOutAlt, FaListAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ButtonNav.css';
import { AuthContext } from '../context/AuthContext.js';

const BottomNav = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated, userRole,logout } = useContext(AuthContext);  // Usa el rol del usuario del contexto
  const [categoriasVacias, setCategoriasVacias] = useState(false);

  
  useEffect(() => {
    const fetchCategorias = async () => {
      const empresaId = localStorage.getItem('empresaId'); // Obtén el ID de la empresa
      if (!empresaId) return;
  
      try {
        const response = await fetch('https://asijeminapis.website:4687/categorias', {
          headers: {
            'Content-Type': 'application/json',
            'empresa-id': empresaId, // Enviar el ID de la empresa
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setCategoriasVacias(data.length === 0); // Si no hay categorías, activa el estado
        } else {
          console.error('Error al obtener categorías:', response.statusText);
        }
      } catch (error) {
        console.error('Error al conectar con el servidor:', error);
      }
    };
  
    fetchCategorias();
  }, []);
 

  return (
    <div className="fixed bottom-0 left-0 right-0 botoneraintferior shadow-lg p-4 grid grid-cols-4 gap-4 sm:flex sm:justify-around">
      <Link to="/inicio" className="flex flex-col items-center justify-center">
        <FaHome className="text-blue-500 text-2xl mb-1" />
        <span className="text-xs">Inicio</span>
      </Link>

      <Link to="/scaner" className="flex flex-col items-center justify-center">
        <FaBarcode className="text-blue-500 text-2xl mb-1" />
        <span className="text-xs">Ingreso Productos en Stock</span>
      </Link>

      <Link to="/ingreso" className="flex flex-col items-center justify-center">
        <FaBoxOpen className="text-green-500 text-2xl mb-1" />
        <span className="text-xs">Alta Nuevos Productos</span>
      </Link>

      <Link to="/stock" className="flex flex-col items-center justify-center">
        <FaWarehouse className="text-gray-500 text-2xl mb-1" />
        <span className="text-xs">Stock de Productos</span>
      </Link>

      <Link to="/salida" className="flex flex-col items-center justify-center">
        <FaArrowDown className="text-orange-500 text-2xl mb-1" />
        <span className="text-xs">Salida de Productos</span>
      </Link>

      <Link to="/consulta" className="flex flex-col items-center">
        <FaSearch className="text-orange-700 text-2xl mb-1" />
        <span className="text-xs">Consultar Producto</span>
      </Link>

      <Link to="/perfil" className="flex flex-col items-center text-center">
        <FaUser className="text-emerald-400 text-2xl mb-2" />
        <span className="text-sm">Mi Perfil</span>
      </Link>

     
      <Link
  to="/categorias"
  className="flex flex-col items-center text-center relative"
  title={categoriasVacias ? "Por favor agregue al menos una categoría" : ""}
>
  {/* Ícono principal */}
  <FaListAlt className="text-blue-700 text-2xl mb-2 relative" />
  
  {/* Notificación superpuesta */}
  {categoriasVacias && (
    <div
      className="absolute bg-yellow-400 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs animate-pulse"
      style={{
        top: '-6px', // Ajusta la posición vertical
        right: '12px', // Ajusta la posición horizontal
      }}
    >
      !
    </div>
  )}
  <span className="text-sm">Categorías</span>
</Link>



      <button onClick={logout} className="flex flex-col items-center text-center">
        <FaSignOutAlt className="text-red-500 text-2xl mb-2" />
        <span className="text-sm">Cerrar Sesión</span>
      </button>


      {userRole === 'dios' && (
        <Link to="/altas" className="flex flex-col items-center justify-center">
          <FaCogs className="text-purple-500 text-2xl mb-1" />
          <span className="text-xs">Altas</span>
        </Link>
      )}
    </div>
  );
};

export default BottomNav;
