

import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaBarcode, FaBoxOpen, FaWarehouse, FaArrowDown, FaClipboardList, FaCogs, FaUser, FaSearch, FaSignOutAlt, FaListAlt  } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';
import "./inicio.css";
const Inicio = () => {
  const [precioStock, setPrecioStock] = useState(null);
  const [loadingStock, setLoadingStock] = useState(true);
  const { userRole, loading, logout } = useContext(AuthContext);
  const [categoriasVacias, setCategoriasVacias] = useState(false);


  useEffect(() => {
    const fetchCategorias = async () => {
      const empresaId = localStorage.getItem('empresaId'); // Obtén el ID de la empresa
      if (!empresaId) return;
  
      try {
        const response = await fetch('https://asijeminapis.website:4687/categorias', {
          headers: {
            'Content-Type': 'application/json',
            'empresa-id': empresaId, // Enviar el ID de la empresa
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setCategoriasVacias(data.length === 0); // Si no hay categorías, activa el estado
        } else {
          console.error('Error al obtener categorías:', response.statusText);
        }
      } catch (error) {
        console.error('Error al conectar con el servidor:', error);
      }
    };
  
    fetchCategorias();
  }, []);
 

  useEffect(() => {
    const fetchPrecioStock = async () => {
      try {
        const response = await fetch('https://asijeminapis.website:4687/productos/precioStock');
        if (response.ok) {
          const data = await response.json();
          setPrecioStock(data.total);
        } else {
          console.error('Error al obtener el precio del stock');
        }
      } catch (error) {
        console.error('Error al conectar con el servidor:', error);
      } finally {
        setLoadingStock(false);
      }
    };

    fetchPrecioStock();
  }, []);

  // Efecto para recargar la página una única vez después de 5 segundos
  useEffect(() => {
    const hasReloaded = sessionStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      const timeoutId = setTimeout(() => {
        window.location.reload();
        sessionStorage.setItem('hasReloaded', 'true'); // Marca que ya se recargó
      }, 1500);

      return () => clearTimeout(timeoutId); // Limpia el timeout si el componente se desmonta antes
    }
  }, []);

  // Si `loading` del contexto o `loadingStock` están activos, mostrar "Cargando..."
  if (loading || loadingStock) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-3xl font-bold mb-6">Menú Principal</h1>
      <div className="grid grid-cols-3 gap-6">
        <Link to="/scaner" className="flex flex-col items-center text-center">
          <FaBarcode className="text-blue-500 text-5xl mb-2" />
          <span className="text-sm">Ingreso Productos en Stock</span>
        </Link>
        <Link to="/ingreso" className="flex flex-col items-center text-center">
          <FaBoxOpen className="text-green-500 text-5xl mb-2" />
          <span className="text-sm">Alta Nuevos Productos</span>
        </Link>
        <Link to="/stock" className="flex flex-col items-center text-center">
          <FaClipboardList className="text-blue-500 text-5xl mb-2" />
          <span className="text-sm">Stock de Productos</span>
        </Link>
        <Link to="/salida" className="flex flex-col items-center text-center">
          <FaArrowDown className="text-orange-500 text-5xl mb-2" />
          <span className="text-sm">Salida de Productos</span>
        </Link>
        <Link to="/stock" className="flex flex-col items-center text-center">
          <FaWarehouse className="text-red-500 text-5xl mb-2" />
          <span className="text-sm">Depósito</span>
        </Link>
        <Link to="/consulta" className="flex flex-col items-center">
          <FaSearch className="text-orange-700 text-5xl mb-1" />
          <span className="text-xs">Consultar Producto</span>
        </Link>
        <Link to="/perfil" className="flex flex-col items-center text-center">
          <FaUser className="text-emerald-400 text-5xl mb-2" />
          <span className="text-sm">Mi Perfil</span>
        </Link>

        <Link
  to="/categorias"
  className="flex flex-col items-center text-center relative"
  title={categoriasVacias ? "Por favor agregue al menos una categoría" : ""}
>
  {/* Ícono principal */}
  <FaListAlt className="text-blue-700 text-5xl mb-2 relative" />
  
  {/* Notificación superpuesta */}
  {categoriasVacias && (
    <div
      className="absolute bg-yellow-400 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs animate-pulse"
      style={{
        top: '-5px', // Ajusta la posición vertical
        right: '50px', // Ajusta la posición horizontal
      }}
    >
      !
    </div>
  )}
  <span className="text-sm">Categorías</span>
</Link>




        <button onClick={logout} className="flex flex-col items-center text-center">
          <FaSignOutAlt className="text-red-500 text-5xl mb-2" />
          <span className="text-sm">Cerrar Sesión</span>
        </button>
        {userRole === 'dios' && (
          <Link to="/altas" className="flex flex-col items-center text-center">
            <FaCogs className="text-purple-500 text-5xl mb-2" />
            <span className="text-sm">Altas</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Inicio;
