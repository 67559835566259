// import React, { useState, useEffect } from 'react';
// import BottomNav from '../ButtonNav/ButtonNav';

// const Categorias = () => {
//   const [categorias, setCategorias] = useState([]);
//   const [nuevaCategoria, setNuevaCategoria] = useState('');
//   const empresaId = localStorage.getItem('empresaId');

//   useEffect(() => {
//     const fetchCategorias = async () => {
//       try {
//         const response = await fetch('http://localhost:4687/categorias', {
//           headers: {
//             'Content-Type': 'application/json',
//             'empresa-id': empresaId, // Enviar el ID de la empresa
//           },
//         });
//         const data = await response.json();
//         setCategorias(data);
//       } catch (error) {
//         console.error('Error al obtener categorías:', error);
//       }
//     };

//     fetchCategorias();
//   }, [empresaId]);

//   const handleAddCategoria = async () => {
//     if (!nuevaCategoria.trim()) return;
//     try {
//       const response = await fetch('http://localhost:4687/categorias', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'empresa-id': empresaId, // Enviar el ID de la empresa
//         },
//         body: JSON.stringify({ nombre: nuevaCategoria }),
//       });

//       if (response.ok) {
//         const nueva = await response.json();
//         setCategorias([...categorias, nueva]);
//         setNuevaCategoria('');
//       } else {
//         console.error('Error al crear la categoría');
//       }
//     } catch (error) {
//       console.error('Error al conectar con el servidor:', error);
//     }
//   };

//   return (
//     <div className="min-h-screen flex flex-col items-center bg-gray-100">
//       <h1 className="text-3xl font-bold my-6">Gestión de Categorías</h1>
//       <div className="w-full max-w-md">
//         <div className="flex mb-4">
//           <input
//             type="text"
//             className="flex-grow border px-4 py-2 rounded-l"
//             placeholder="Nueva Categoría"
//             value={nuevaCategoria}
//             onChange={(e) => setNuevaCategoria(e.target.value)}
//           />
//           <button
//             className="bg-blue-500 text-white px-4 py-2 rounded-r"
//             onClick={handleAddCategoria}
//           >
//             Agregar
//           </button>
//         </div>
//         <ul className="list-disc list-inside bg-white p-4 rounded shadow">
//           {categorias.map((categoria) => (
//             <li key={categoria._id}>{categoria.nombre}</li>
//           ))}
//         </ul>
//       </div>
//       <BottomNav />
//     </div>
//   );
// };

// export default Categorias;









import React, { useState, useEffect } from 'react';
import BottomNav from '../ButtonNav/ButtonNav';

const Categorias = () => {
  const [categorias, setCategorias] = useState([]);
  const [nuevaCategoria, setNuevaCategoria] = useState('');
  const empresaId = localStorage.getItem('empresaId');

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await fetch('https://asijeminapis.website:4687/categorias', {
          headers: {
            'Content-Type': 'application/json',
            'empresa-id': empresaId, // Enviar el ID de la empresa
          },
        });
        const data = await response.json();
        setCategorias(data);
      } catch (error) {
        console.error('Error al obtener categorías:', error);
      }
    };

    fetchCategorias();
  }, [empresaId]);

  const handleAddCategoria = async () => {
    if (!nuevaCategoria.trim()) return;
    try {
      const response = await fetch('https://asijeminapis.website:4687/categorias', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'empresa-id': empresaId, // Enviar el ID de la empresa
        },
        body: JSON.stringify({ nombre: nuevaCategoria }),
      });

      if (response.ok) {
        const nueva = await response.json();
        setCategorias([...categorias, nueva]);
        setNuevaCategoria('');
      } else {
        console.error('Error al crear la categoría');
      }
    } catch (error) {
      console.error('Error al conectar con el servidor:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100">
      <h1 className="text-3xl font-bold my-6">Gestión de Categorías</h1>

      {/* Aviso importante */}
      <div className="bg-yellow-200 text-yellow-800 border border-yellow-400 p-4 rounded mb-6 shadow-md max-w-md">
        <p>
          <strong>Importante:</strong> Recuerde que el nombre de las categorías debe ser asignado cuidadosamente, <strong> ya que no se pueden eliminar</strong> debido a su impacto en los productos asociados y otras operaciones del sistema.
        </p>
      </div>

      <div className="w-full max-w-md">
        <div className="flex mb-4">
          <input
            type="text"
            className="flex-grow border px-4 py-2 rounded-l"
            placeholder="Nueva Categoría"
            value={nuevaCategoria}
            onChange={(e) => setNuevaCategoria(e.target.value)}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-r ml-3"
            onClick={handleAddCategoria}
          >
            Agregar
          </button>
        </div>
        <ul className="list-disc list-inside bg-white p-4 rounded shadow">
          {categorias.map((categoria) => (
            <li className='capitalize' key={categoria._id}>{categoria.nombre }</li>
          ))}
        </ul>
      </div>
      <BottomNav />
    </div>
  );
};

export default Categorias;
