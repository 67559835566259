

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './Login.css';

function Login() {
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [contraseña, setContraseña] = useState('');
  const { login, errorMessage } = useContext(AuthContext); // Accede a errorMessage directamente desde el contexto
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login({ nombreUsuario, contraseña });
      navigate('/inicio');
    } catch {
      // No es necesario manejar el error aquí, ya que errorMessage se actualiza en el contexto
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Stock Facil</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Nombre de Usuario:</label>
            <input
              type="text"
              value={nombreUsuario}
              onChange={(e) => setNombreUsuario(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Contraseña:</label>
            <input
              type="password"
              value={contraseña}
              onChange={(e) => setContraseña(e.target.value)}
              required
            />
          </div>
          {/* Muestra el mensaje de error desde errorMessage del contexto */}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button type="submit" className="login-button">Ingresar</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
