


import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BarcodeScanner from './components/Scaner/Scaner';
import Inicio from './components/Inicio/Inicio';
import Stock from './components/Stock/Stock';
import Salida from './components/Salida/Salida';
import Ingreso from './components/Ingreso/Ingreso';
import Consulta from './components/Consulta/Consulta';
import Login from './components/Login/Login';
import Perfil from './components/Perfil/Perfil';
import Categorias from './components/Categorias/Categorias';
import RegistroAdmin from './components/RegistroAdmin/RegistroAdmin';
import Altas from './components/Altas/Altas'; 
import { AuthProvider } from './components/context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/alta-empresa" element={<RegistroAdmin />} />
            {/* Rutas protegidas */}
            <Route
              path="/inicio"
              element={
                <ProtectedRoute>
                  <Inicio />
                </ProtectedRoute>
              }
            />
            <Route
              path="/scaner"
              element={
                <ProtectedRoute>
                  <BarcodeScanner />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stock"
              element={
                <ProtectedRoute>
                  <Stock />
                </ProtectedRoute>
              }
            />
            <Route
              path="/salida"
              element={
                <ProtectedRoute>
                  <Salida />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ingreso"
              element={
                <ProtectedRoute>
                  <Ingreso />
                </ProtectedRoute>
              }
            />
            <Route
              path="/consulta"
              element={
                <ProtectedRoute>
                  <Consulta />
                </ProtectedRoute>
              }
            />
            <Route
              path="/perfil"
              element={
                <ProtectedRoute>
                  <Perfil />
                </ProtectedRoute>
              }
            />

            <Route
              path="/categorias"
              element={
                <ProtectedRoute>
                  <Categorias />
                </ProtectedRoute>
              }
            />
            {/* Ruta protegida específica para el rol "dios" */}
            <Route
              path="/altas"
              element={
                <ProtectedRoute requiredRole="dios">
                  <Altas />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
