

import React, { useState, useEffect } from 'react';
import BottomNav from '../ButtonNav/ButtonNav.jsx';
import { FaExclamationTriangle } from 'react-icons/fa'; // Importar el icono de advertencia

const Stock = () => {
  const [productos, setProductos] = useState([]);

  const fetchProductos = async () => {
    try {
      const empresaId = localStorage.getItem('empresaId'); // Obtener el empresaId desde localStorage
      const response = await fetch(`https://asijeminapis.website:4687/productos?empresaId=${empresaId}`, {
        credentials: 'include', // Incluir credenciales si es necesario
      }); // Pasar el empresaId en la URL

      const data = await response.json();

      // Asegurarse de que la respuesta es un array
      if (Array.isArray(data)) {
        setProductos(data);
      } else {
        console.error('La respuesta de la API no es un array:', data);
        setProductos([]); // Configura un array vacío si la respuesta no es un array
      }
    } catch (error) {
      console.error('Error al obtener los productos:', error);
      setProductos([]); // Configura un array vacío si hay un error
    }
  };

  useEffect(() => {
    fetchProductos();
  }, []);

  // Función para determinar las clases y los iconos de advertencia en la fila del producto
  const determinarAdvertencias = (producto) => {
    const hoy = new Date(); // Fecha actual
    hoy.setHours(0, 0, 0, 0); // Normalizar a las 00:00:00 para evitar errores

    const fechaVencimiento = producto.fecha_vencimiento
      ? new Date(producto.fecha_vencimiento) // Trabajar con fecha_vencimiento como Date
      : null;

    const unaSemana = 7 * 24 * 60 * 60 * 1000; // Una semana en milisegundos

    let claseFila = 'bg-white text-gray-900'; // Estilo por defecto
    let hoverClass = 'hover:bg-gray-100'; // Hover por defecto
    let advertenciaFecha = null;
    let advertenciaStock = null;

    // Comprobación de la fecha de vencimiento
    if (fechaVencimiento && !isNaN(fechaVencimiento.getTime())) {
      fechaVencimiento.setHours(0, 0, 0, 0); // Normalizar también la fecha de vencimiento
      if (fechaVencimiento < hoy) {
        // Producto vencido
        claseFila = 'bg-red-500 text-white'; // Fondo rojo y texto blanco
        hoverClass = 'hover:bg-red-600'; // Hover rojo más oscuro
        advertenciaFecha = <FaExclamationTriangle className="inline-block text-white ml-2" />;
      } else if (fechaVencimiento.getTime() - hoy.getTime() <= unaSemana) {
        // Producto a punto de vencer
        claseFila = 'bg-yellow-300 text-black'; // Fondo amarillo y texto negro
        hoverClass = 'hover:bg-yellow-400'; // Hover amarillo más oscuro
        advertenciaFecha = <FaExclamationTriangle className="inline-block text-black ml-2" />;
      }
    }

    // Comprobación de stock
    if (producto.stock <= producto.stock_minimo && producto.stock_minimo > 0) {
      // Stock en el mínimo o agotado
      claseFila = 'bg-red-500 text-white';
      hoverClass = 'hover:bg-red-600';
      advertenciaStock = <FaExclamationTriangle className="inline-block text-white ml-2" />;
    } else if (producto.stock > producto.stock_minimo && producto.stock <= producto.stock_minimo * 1.5) {
      // Stock bajo pero no mínimo
      claseFila = 'bg-yellow-300 text-black';
      hoverClass = 'hover:bg-yellow-400';
      advertenciaStock = <FaExclamationTriangle className="inline-block text-black ml-2" />;
    }

    return { claseFila, hoverClass, advertenciaFecha, advertenciaStock };
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Stock de Productos</h2>
      <div className="overflow-x-auto shadow-md sm:rounded-lg mb-20 w-6/8 mx-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50">
            <tr>
              <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">Nombre</th>
              <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">Precio Venta</th>
              <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">Lugar de Almacenamiento</th>
              <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">Vencimiento</th>
              <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">Stock</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {productos.length > 0 ? (
              productos.map((producto) => {
                const { claseFila, hoverClass, advertenciaFecha, advertenciaStock } = determinarAdvertencias(producto);
                return (
                  <tr key={producto._id} className={`${claseFila} ${hoverClass}`}>
                    <td className="py-4 px-6 text-sm font-medium">{producto.nombre}</td>
                    <td className="py-4 px-6 text-sm">${producto.precioVenta}</td>
                    <td className="py-4 px-6 text-sm">{producto.lugar_almacenamiento}</td>
                    <td className="py-4 px-6 text-sm">
                      {producto.fecha_vencimiento_formateada ? producto.fecha_vencimiento_formateada : 'Sin fecha'}
                      {advertenciaFecha}
                    </td>
                    <td className="py-4 px-6 text-sm">
                      {producto.stock}
                      {advertenciaStock}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" className="py-4 px-6 text-sm font-medium text-center">
                  No hay productos disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <BottomNav />
    </div>
  );
};

export default Stock;
